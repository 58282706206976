@media (min-width: 320px) {
	// NAWIGACJA
	.nav {
		&__wrapper {
			img {
				height: 45px;
				width: 55px;
			}
		}
	}
	// O NAS
	.about-us {
		height: auto;
	}
	// OFERTA
	.offert {
		&__brw {
			margin-top: 6em;
			padding: 3em 2em;
			.ti {
				display: block;
				padding: 0.5em;
				font-size: 2.4rem;
				text-align: center;
				color: $gray;
				background-color: none;
			}
		}

		&__description {
			margin: 0 1em;
		}
	}
	//FOOTER
	.footer {
		&__fb-button {
			font-size: 3rem;
		}
	}
}
