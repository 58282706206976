*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  scroll-padding-top: 79px;
  scroll-behavior: smooth;
}

body {
  position: relative;
  height: 100%;
  width: 100%;
  font-family: "Inter", sans-serif;
  font-size: 1.6rem;
  background-color: #333;
}

.wrapper {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.section-title {
  display: block;
  padding: 0.2em;
  margin: 2em 0 2em;
  font-size: 2.4rem;
  font-weight: bold;
  color: #FB0404;
  border-radius: 8px;
  text-transform: uppercase;
  cursor: default;
  transition: background-color 0.3s, color 0.3s;
}

.button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
  font-size: 4.5rem;
  border-radius: 50%;
  cursor: pointer;
  padding: 0.4em;
}
.button__link {
  color: #111;
  text-decoration: none;
}
.button__link .ti-arrow-up {
  background-color: #01baef;
  border-radius: 50%;
}

.slick-slider .slick-prev {
  position: absolute;
  bottom: -15%;
  margin: 0 0 2em;
  background: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  transform: translate(-40%, 0);
  cursor: pointer;
  left: 40%;
  border-left: 0 solid transparent;
  border-right: 15px solid #e8e8e8;
}
.slick-slider .slick-next {
  position: absolute;
  bottom: -15%;
  margin: 0 0 2em;
  background: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  transform: translate(-40%, 0);
  cursor: pointer;
  right: 40%;
  border-right: 0 solid transparent;
  border-left: 15px solid #e8e8e8;
}

.slick-dots {
  display: flex;
  justify-content: center;
  padding: 2em 0;
  list-style-type: none;
}
.slick-dots li {
  margin: 0 0.5em;
}
.slick-dots button {
  display: block;
  width: 1rem;
  height: 1rem;
  padding: 0;
  border: none;
  border-radius: 50%;
  background-color: #e8e8e8;
  text-indent: -9999px;
}
.slick-dots li.slick-active button {
  background-color: #FB0404;
}

section {
  display: flex;
  flex-direction: column;
}

hr {
  margin: 50px auto 40px;
  height: 1px;
  width: 80%;
  max-width: 800px;
  border: 1px solid transparent;
  background-color: #FB0404;
}

.header {
  position: relative;
  height: 50vh;
}
.header__img {
  position: absolute;
  width: 100%;
  height: 100%;
}
.header__img-mobile {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  object-fit: cover;
}
.header__img-desktop {
  display: none;
}
.header__shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.nav {
  position: fixed;
  height: 80px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 2;
}
.nav--active {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  font-size: 1.8rem;
  z-index: 50;
  background-color: #000;
}
.nav__wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 90%;
  margin: auto;
}
.nav__wrapper .hamburger {
  position: absolute;
  top: 2.5em;
  right: 1em;
  transform: translateY(-50%);
  z-index: 500;
}
.nav__wrapper .hamburger-inner, .nav__wrapper .hamburger-inner::before, .nav__wrapper .hamburger-inner::after {
  background-color: #FB0404;
  z-index: 500;
}
.nav__wrapper img {
  position: absolute;
  top: 2.5em;
  left: 2em;
  transform: translateY(-50%);
  height: 40px;
  width: 45px;
  border-radius: 12px;
  background-color: #f6f6f6;
  cursor: pointer;
  z-index: 500;
}
.nav__item {
  visibility: hidden;
}
.nav__item--active {
  padding: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #FB0404;
  text-decoration: none;
  visibility: visible;
  z-index: 100;
}

.about-us {
  padding-bottom: 3em;
  height: auto;
  width: 100%;
  background-color: #111;
}
.about-us__text {
  padding: 2em 1em;
  width: 80%;
  font-size: 1.4rem;
  border: 2px solid #FB0404;
  border-radius: 25px;
  background-color: #e8e8e8;
  text-align: center;
  overflow: hidden;
}
.about-us__text span {
  font-weight: bold;
  font-size: 1.6rem;
}
.about-us__text p {
  text-align: justify;
}
.about-us__hr {
  margin: 1em auto;
}

.offert {
  padding-bottom: 5em;
  height: auto;
  width: 100%;
  background-color: #111;
}
.offert .wrapper {
  height: 100%;
}
.offert__center {
  margin: 0 auto 0;
  padding: 0 0 2em;
  max-width: 100%;
}
.offert__center-card {
  width: 260px;
  height: 260px;
  padding: 10px;
  text-align: center;
}
.offert__center-card h3 {
  margin: 0 auto;
  width: 90%;
  padding-top: 1em;
  color: #e8e8e8;
}
.offert__center-card img {
  width: 100%;
  height: 65%;
  border-radius: 25px;
}
.offert__brw {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
  padding: 2em 1em;
  border: 2px solid #FB0404;
  border-radius: 25px;
  background-color: #000;
}
.offert__brw-text {
  color: #e8e8e8;
  font-size: 1.6rem;
  text-align: center;
}
.offert__brw-text span {
  width: 100%;
  color: #FB0404;
  font-weight: bold;
}
.offert__brw-text--link a {
  padding: 0.3em;
  border-radius: 8px;
  text-decoration: none;
  text-transform: uppercase;
  color: #01baef;
  font-weight: bold;
  margin: 0 auto;
}
.offert__description {
  color: #e8e8e8;
  text-align: center;
}
.offert__description--first .ti {
  padding: 0.5em 0;
  font-size: 5rem;
  font-display: swap;
  transition: color 0.3s;
}
.offert__description--first .ti:hover {
  color: #FB0404;
}
.offert__description--second .lni {
  padding: 0 0.5em;
  font-size: 5rem;
  font-display: swap;
  transition: color 0.3s;
}
.offert__description--second .lni:hover {
  color: #FB0404;
}
.offert__description-text {
  padding-bottom: 2em;
}

.contact {
  width: 100%;
  height: auto;
  color: #e8e8e8;
  background-color: #111;
  text-align: center;
}
.contact__box {
  width: 100%;
}
.contact__box .ti {
  font-size: 3.8rem;
  font-display: swap;
  transition: color 0.3s;
}
.contact__box .ti:hover {
  color: #FB0404;
}
.contact__box-adress p {
  padding: 1.5em 0;
}
.contact__box-phone p {
  padding: 0 0 0.4em;
}
.contact__box-phone p:nth-of-type(1) {
  padding: 1.5em 0 0.4em;
}
.contact__box-mail {
  margin-bottom: 3em;
}
.contact__box-mail p:nth-of-type(1) {
  padding: 1.5em 0 0;
}
.contact__facebook a {
  font-size: 4rem;
  color: #e8e8e8;
  text-decoration: none;
}
.contact__facebook a .lni {
  font-display: swap;
  padding: 0.2em 0 2em;
}

.shop-gallery {
  position: relative;
  height: auto;
  width: 100%;
  background-color: #111;
}
.shop-gallery .section-title {
  text-align: center;
}
.shop-gallery .wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.shop-gallery .wrapper .card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 250px;
  height: 200px;
  margin: 1em 1em 3em;
  background-color: #111;
  border-radius: 25px;
  border: 2px solid #FB0404;
}
.shop-gallery .wrapper .card__img {
  display: none;
  width: 100%;
  height: 50%;
  border-radius: 22px 22px 0 0;
}
.shop-gallery .wrapper .card__body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 50%;
  color: #e8e8e8;
}
.shop-gallery .wrapper .card__body h3 {
  padding: 0 1em;
  text-align: center;
  font-size: 1.8rem;
}
.shop-gallery .wrapper .card__text {
  padding: 1em 0;
}
.shop-gallery .wrapper .card__footer {
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  width: 100%;
  height: auto;
  background-color: #111;
  border-radius: 0 0 22px 22px;
}
.shop-gallery .wrapper .card__footer .lni {
  color: #e8e8e8;
  font-size: 3.5rem;
  font-display: swap;
  transition: color 0.3s;
}
.shop-gallery .wrapper .card__footer .lni:hover {
  color: #01baef;
}
.shop-gallery .wrapper .card__footer a {
  text-decoration: none;
  color: #e8e8e8;
  font-size: 3.5rem;
  bottom: 10px;
}
.shop-gallery .wrapper .card__footer a .ti {
  font-size: 3.5rem;
  font-display: swap;
  transition: color 0.3s;
}
.shop-gallery .wrapper .card__footer a .ti:hover {
  color: #FB0404;
}
.shop-gallery .wrapper .card__footer--www {
  padding-right: 1em;
  font-display: swap;
}

.footer {
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  font-size: 1.2rem;
  color: #f6f6f6;
  background-color: #222;
}
.footer__fb-button {
  font-size: 2rem;
  padding: 0.5em;
  color: #e8e8e8;
}
.footer__fb-button--link {
  text-decoration: none;
  color: #01baef;
}

@media (min-width: 320px) {
  .nav__wrapper img {
    height: 45px;
    width: 55px;
  }
  .about-us {
    height: auto;
  }
  .offert__brw {
    margin-top: 6em;
    padding: 3em 2em;
  }
  .offert__brw .ti {
    display: block;
    padding: 0.5em;
    font-size: 2.4rem;
    text-align: center;
    color: #e8e8e8;
    background-color: none;
  }
  .offert__description {
    margin: 0 1em;
  }
  .footer__fb-button {
    font-size: 3rem;
  }
}
@media (min-width: 576px) {
  .nav__wrapper img {
    height: 50px;
    width: 60px;
  }
  .nav--active {
    font-size: 2.5rem;
  }
  .about-us__text {
    font-size: 1.6rem;
    text-align: center;
  }
  .about-us__text span {
    font-size: 2rem;
  }
  .offert__brw {
    margin-top: 5em;
    padding: 3em 4em;
  }
  .offert h3 {
    padding-top: 1em;
    color: #e8e8e8;
  }
}
@media (min-width: 768px) {
  .header {
    height: 60vh;
  }
  .header .nav__wrapper {
    flex-direction: row;
    justify-content: flex-end;
    width: 90vw;
  }
  .header .nav__wrapper .hamburger {
    display: none;
  }
  .header .nav__wrapper img {
    height: 50px;
    width: 60px;
  }
  .header .nav__item {
    visibility: visible;
    padding: 1em 1.5em;
    font-weight: bold;
    color: #FB0404;
    text-decoration: none;
    z-index: 100;
  }
  .about-us__text {
    font-size: 1.6rem;
  }
  .offert__brw {
    padding: 2em 4em;
  }
  .offert__brw-text {
    font-size: 1.8rem;
  }
  .offert__brw-text span {
    font-size: 2.2rem;
  }
  .offert__brw-text--link a {
    font-size: 2rem;
  }
  .offert__description {
    font-size: 2rem;
  }
  .offert__description--first .ti {
    font-size: 6rem;
  }
  .offert__description--second .lni {
    font-size: 6rem;
  }
  .contact__box {
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: row;
  }
  .contact__box .ti {
    font-size: 4.4rem;
  }
  .contact__box-adress, .contact__box-phone, .contact__box-mail {
    width: 30%;
  }
  .contact__facebook a {
    font-size: 5rem;
  }
  .contact__facebook a .lni {
    color: #e8e8e8;
    margin: 1em 0;
    padding: 0.2em;
    transition: color 0.3s;
  }
  .contact__facebook a .lni:hover {
    color: #01baef;
  }
  .contact hr {
    display: none;
  }
  .footer {
    height: 80px;
    font-size: 1.6rem;
  }
  .footer__fb-button {
    font-size: 3.5rem;
    padding: 0.5em 1em;
  }
}
@media (min-width: 992px) {
  .slick-slider .slick-prev {
    transition: border 0.3s;
  }
  .slick-slider .slick-prev:hover {
    border-right: 15px solid #FB0404;
  }
  .slick-slider .slick-next {
    transition: border 0.3s;
  }
  .slick-slider .slick-next:hover {
    border-left: 15px solid #FB0404;
  }
  .header {
    height: 80vh;
  }
  .header__img-mobile {
    display: none;
  }
  .header__img-desktop {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
  }
  .header .nav__wrapper {
    width: 90vw;
  }
  .header .nav__wrapper img {
    height: 60px;
    width: 80px;
  }
  .header .nav__item {
    padding: 1.5em 2em;
  }
  .about-us__text {
    font-size: 1.8rem;
  }
  .about-us__hr {
    margin: 2em auto;
  }
  .offert__brw {
    margin-top: 5em;
    padding: 3em 6em;
  }
  .offert__brw-text {
    font-size: 2rem;
    color: #e8e8e8;
    text-align: center;
  }
  .offert__brw-text span {
    font-size: 2.4rem;
  }
  .offert__brw-text--link a {
    font-size: 2.2rem;
    transition: color 0.3s;
  }
  .offert__brw-text--link a:hover {
    color: #FB0404;
  }
  .offert__description {
    font-size: 2.4rem;
  }
  .offert__description--first .ti {
    font-size: 7rem;
  }
  .offert__description--second .lni {
    font-size: 7rem;
  }
  .contact__box {
    margin-bottom: 4em;
  }
  .contact__box .ti {
    font-size: 5.2rem;
  }
  .contact__box p:nth-of-type(n + 1) {
    font-size: 1.8rem;
  }
  .contact__facebook a {
    font-size: 5.2rem;
  }
  .shop-gallery {
    background-color: #111;
  }
  .shop-gallery .wrapper .card {
    height: 400px;
    background-color: #e8e8e8;
  }
  .shop-gallery .wrapper .card__img {
    display: block;
  }
  .shop-gallery .wrapper .card__body {
    color: #111;
  }
  .shop-gallery .wrapper .card__footer {
    background-color: #e8e8e8;
  }
  .shop-gallery .wrapper .card__footer .lni {
    color: #111;
  }
  .shop-gallery .wrapper .card__footer a {
    color: #111;
  }
  .footer {
    height: 100px;
    font-size: 1.8rem;
    color: #f6f6f6;
    background-color: #222;
  }
  .footer__fb-button {
    font-size: 4rem;
  }
  .footer__fb-button--link {
    transition: color 0.3s;
  }
  .footer__fb-button--link:hover {
    color: #e8e8e8;
  }
}
@media (min-width: 1200px) {
  .section-title {
    font-size: 3.2rem;
    letter-spacing: 1px;
  }
  .header .nav__wrapper {
    max-width: 1200px;
  }
  .header .nav__wrapper img {
    height: 60px;
    width: 80px;
  }
  .header .nav__item {
    position: relative;
    padding: 1.5em 2.5em;
    font-weight: bold;
    color: #FB0404;
    border-radius: 8px;
    text-decoration: none;
    z-index: 100;
    transition: color 0.3s, border 0.3s;
  }
  .header .nav__item:hover {
    color: #e8e8e8;
  }
  .about-us__text {
    padding: 4em 3em;
  }
  .about-us__text span {
    font-size: 2.2rem;
  }
  .about-us__hr {
    margin: 3em auto;
  }
  .offert__brw {
    margin-top: 8em;
    padding: 4em 8em;
  }
  .offert__center-card {
    padding: 10px;
    text-align: center;
    transition: transform 0.3s;
  }
  .offert__center-card:hover {
    transform: scale(0.95);
  }
  .offert__center-card h3 {
    padding-top: 1em;
    color: #e8e8e8;
  }
  .offert__center-card img {
    width: 100%;
  }
  .contact__box .ti {
    font-size: 6rem;
  }
  .contact__box p:nth-of-type(n + 1) {
    font-size: 2.2rem;
  }
  .contact__facebook a {
    font-size: 6rem;
  }
  .footer {
    padding: 2em 0;
    font-size: 2rem;
  }
}
/*
postion
top/left/bottom/right
display

margin
padding
width
height

color
bgc
border

font-size
line-height

transition
cursor
*/