@media (min-width: 768px) {
	// HEADER + NAWIGACJA
	.header {
		height: 60vh;

		.nav {
			&__wrapper {
				flex-direction: row;
				justify-content: flex-end;
				width: 90vw;

				.hamburger {
					display: none;
				}

				img {
					height: 50px;
					width: 60px;
				}
			}

			&__item {
				visibility: visible;
				padding: 1em 1.5em;
				font-weight: bold;
				color: $red;
				text-decoration: none;
				z-index: 100;
			}
		}
	}

	//O NAS
	.about-us {
		&__text {
			font-size: 1.6rem;
		}
	}

	// OFERTA
	.offert {
		&__brw {
			padding: 2em 4em;

			&-text {
				font-size: 1.8rem;

				span {
					font-size: 2.2rem;
				}
				&--link a {
					font-size: 2rem;
				}
			}
		}
		&__description {
			font-size: 2rem;

			&--first {
				.ti {
					font-size: 6rem;
				}
			}
			&--second {
				.lni {
					font-size: 6rem;
				}
			}
		}
	}
	// KONTAKT
	.contact {
		&__box {
			margin: 0 auto;
			display: flex;
			justify-content: space-evenly;
			align-items: flex-start;
			flex-direction: row;
			.ti {
				font-size: 4.4rem;
			}
			&-adress,
			&-phone,
			&-mail {
				width: 30%;
			}
		}

		&__facebook a {
			font-size: 5rem;

			.lni {
				color: $gray;
				margin: 1em 0;
				padding: 0.2em;
				transition: color 0.3s;

				&:hover {
					color: $blue;
				}
			}
		}
		hr {
			display: none;
		}
	}

	// FOOTER
	.footer {
		height: 80px;
		font-size: 1.6rem;

		&__fb-button {
			font-size: 3.5rem;
			padding: 0.5em 1em;
		}
	}
}
