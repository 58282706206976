@media (min-width: 992px) {
	// ARROWS - SLIDER
	.slick-slider {
		.slick-prev {
			transition: border 0.3s;

			&:hover {
				border-right: 15px solid $red;
			}
		}
		.slick-next {
			transition: border 0.3s;

			&:hover {
				border-left: 15px solid $red;
			}
		}
	}
	// HEADER + NAWIGACJA
	.header {
		height: 80vh;
		&__img {
			&-mobile {
				display: none;
			}

			&-desktop {
				display: block;
				position: absolute;
				width: 100%;
				height: 100%;
				background-size: cover;
				background-position: center;
				object-fit: cover;
			}
		}
		.nav {
			&__wrapper {
				width: 90vw;

				img {
					height: 60px;
					width: 80px;
				}
			}
			&__item {
				padding: 1.5em 2em;
			}
		}
	}

	// O NAS
	.about-us {
		&__text {
			font-size: 1.8rem;
		}

		&__hr {
			margin: 2em auto;
		}
	}

	// OFERTA
	.offert {
		&__brw {
			margin-top: 5em;
			padding: 3em 6em;

			&-text {
				font-size: 2rem;
				color: $gray;
				text-align: center;

				span {
					font-size: 2.4rem;
				}
				&--link a {
					font-size: 2.2rem;
					transition: color 0.3s;
					&:hover {
						color: $red;
					}
				}
			}
		}
		&__description {
			font-size: 2.4rem;

			&--first {
				.ti {
					font-size: 7rem;
				}
			}
			&--second {
				.lni {
					font-size: 7rem;
				}
			}
		}
	}

	// KONTAKT
	.contact {
		&__box {
			margin-bottom: 4em;
			.ti {
				font-size: 5.2rem;
			}

			p:nth-of-type(n + 1) {
				font-size: 1.8rem;
			}
		}

		&__facebook a {
			font-size: 5.2rem;
		}
	}

	// GALERIA HIT
	.shop-gallery {
		background-color: $darkest;

		.wrapper {
			.card {
				height: 400px;
				background-color: $gray;
				&__img {
					display: block;
				}

				&__body {
					color: $darkest;
				}

				&__footer {
					background-color: $gray;

					.lni {
						color: $darkest;
					}

					a {
						color: $darkest;
					}
				}
			}
		}
	}

	// FOOTER
	.footer {
		height: 100px;
		font-size: 1.8rem;
		color: $white;
		background-color: $darker;

		&__fb-button {
			font-size: 4rem;

			&--link {
				transition: color 0.3s;
				&:hover {
					color: $gray;
				}
			}
		}
	}
}
